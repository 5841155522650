html,
body,
#root {
  white-space: pre-wrap;
}

input {
  white-space: initial;
}

body {
  margin: 0;
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.font-roboto {
  font-family: "Roboto", "Lato", sans-serif !important;
}

.font-quicksand {
  font-family: "Quicksand", "Lato", sans-serif !important;
}

.font-caveat {
  font-family: "Caveat", "Lato", sans-serif !important;
}

.font-rochester {
  font-family: "Rochester", "Lato", sans-serif !important;
}

.li-button {
  background-color: transparent;
  border-radius: 50%;
  border: 0px;
  cursor: pointer;
  height: 40px;
  outline: 0px;
  padding: 8px;
  transition: all 0.3s ease-in-out 0s;
  flex-shrink: 0;
  width: 40px;
}

.li-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.li-button:active {
  background-color: rgba(0, 0, 0, 0.2);
}

.gender-icon svg {
  height: 14px;
  width: 14px;
  vertical-align: middle;
}

.alert {
  position: absolute !important;
  left: 50%;
  transform: translateX(-50%);
  top: 100px;
  z-index: 9999;
}

.cursor-pointer {
  cursor: pointer;
}

.user-profile-section {
  width: 100%;
}

.user-profile-section:first-child {
  height: 100dvh;
}

.text-blue {
  color: #177EC0;
}

.font-medium {
  font-size: 1.25em !important;
}

.font-large {
  font-size: 1.5em !important;
}

#CookiebotWidget {
  display: none;
}

.m-0 {
  margin: 0 !important;
}

.MuiAvatar-img, .jss12{
  max-width: 200px !important;
}

.MuiAvatar-root, .MuiAvatar-img {
  width: 100% !important;
  height: 100% !important;
  max-width: 200px !important;
  max-height: 200px !important;
}

.jss472 {
  width: 40px !important;
  height: 40px !important;
} 

.ccccn .cc-bottom-buttons-icon-container {
  width: 40px !important;
  height: 40px !important;
  max-width: 40px !important;
  max-height: 40px !important;
}

.ccccn .cc-video-container {
  min-height: 100px;
  max-height: 80vh;
  max-width: 80vw;
}

.w-100 {
  width: 100%;
}